import * as React from "react";

import './App.css';
import HeaderSection from "./components/HeaderSection/HeaderSection";
import HeroSection from "./components/HeroSection/HeroSection";
import FunnyImages from "./components/FunnyImageSection/funy";
import FooterSection from './components/FooterSection/FooterSection';
import Audio from "./components/Audio/Audio";
import Video from "./components/Video/Video"

function App() {
  return (
    <div className="mainDiv">
      <HeaderSection />
      <HeroSection />
      <FunnyImages />
      <Audio />
      <Video />
      <FooterSection />
    </div>

  );
}

export default App;