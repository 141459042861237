// AudioPlayer.js
import React from 'react';
import styled from 'styled-components';

const AudioDiv = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  margin-top: 50px;
    @media (max-width: 1028px) {
    width: 50%;
    }
     @media (max-width: 540px) {
    width: 85%;
  margin-top: 0px;
    }
`;


const AudioPlayer = ({ src }) => {
    return (
        <AudioDiv>
            <audio controls style={{ width: '100%' }}>
                <source src={src} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </AudioDiv>
    );
};

export default AudioPlayer;