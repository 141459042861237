import React from 'react';
import styled from 'styled-components';

// Define a styled component for the tooltip
const TooltipWrapper = styled.div`
    position: absolute;
    top: calc(0% - 35px); /* Position below the copied element */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    padding: 5px 10px;
    background-color: var(--font-color2);
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0.9;
    font-weight: 100;
    @media (max-width: 1028px) {
        left: 50%; 
        font-size: 9px;
    }
    @media (max-width: 540px) {
        padding: 3px 10px;
        top: calc(0% - 17px); /* Position below the copied element */
    }
`;

// Define the Tooltip component
const Tooltip = ({ children }) => {
    return (
        <TooltipWrapper>
            {children}
        </TooltipWrapper>
    );
};

export default Tooltip;