import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { telegramurl, twitterurl, dextool, uniswapurl, linktreeurl, raydiumurl } from '../../url';
import WalletAddress from '../HeroSection/WalletAddressButton'

function Header() {
  const [isMenuOpenNav, setIsMenuOpenNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRefNav = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if clicked outside of it
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleMenuClose();
      }
    };

    // Add event listener to the window when the dropdown is open
    if (isMenuOpen) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const handleClickOutsideNav = (event) => {
      // Close the dropdown if clicked outside of it
      if (dropdownRefNav.current && !dropdownRefNav.current.contains(event.target)) {
        handleMenuCloseNav();
      }
    };

    // Add event listener to the window when the dropdown is open
    if (isMenuOpenNav) {
      window.addEventListener('click', handleClickOutsideNav);
    }

    return () => {
      window.removeEventListener('click', handleClickOutsideNav);
    };
  }, [isMenuOpenNav]);

  const isMobileOrTablet = () => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return screenWidth <= 1028;
  }
  const isMobileOrTabletScreen = isMobileOrTablet();

  // Function to close the dropdown
  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuCloseNav = () => {
    setIsMenuOpenNav(false);
  };

  return (
    <>
      <HeaderWrapper>
        {isMobileOrTabletScreen ? (
          <>
            <LogoDiv>
              <Logo src="/img/Logo.png" alt="Logo" />
            </LogoDiv>
            <WalletBtn >
              <WalletAddress />
            </WalletBtn>
            <SocialMedia>
              <DropdownContent>
                {raydiumurl && (
                  <DropdownItem href={raydiumurl} target="_blank">
                    <img src="/img/raydium.png" alt="raydium" />
                  </DropdownItem>
                )}
                {uniswapurl && (
                  <DropdownItem href={uniswapurl} target="_blank">
                    <img src="/img/icon.png" alt="uniswap" />
                  </DropdownItem>
                )}
                {twitterurl && (
                  <DropdownItem href={twitterurl} target="_blank">
                    <img src="/img/twitter.png" alt="twitter" />
                  </DropdownItem>
                )}
                {telegramurl && (
                  <DropdownItem href={telegramurl} target="_blank">
                    <img src="/img/telegram.png" alt="telegram" />
                  </DropdownItem>
                )}
                {dextool && (
                  <DropdownItem href={dextool} target="_blank">
                    <img src="/img/dextool.png" alt="dextool" />
                  </DropdownItem>
                )}
                {linktreeurl && (
                  <DropdownItem href={linktreeurl} target="_blank">
                    <img src="/img/linktree.png" alt="linktree" />
                  </DropdownItem>
                )}
              </DropdownContent>
            </SocialMedia>
          </>
        ) : (
          <>
            <LogoDiv>
              <Logo src="/img/Logo.png" alt="Logo" />
            </LogoDiv>
            <SocialMedia>
              <DropdownContent>
                {uniswapurl && (
                  <DropdownItem href={uniswapurl} target="_blank">
                    <img src="/img/icon.png" alt="uniswap" />
                  </DropdownItem>
                )}
                {twitterurl && (
                  <DropdownItem href={twitterurl} target="_blank">
                    <img src="/img/twitter.png" alt="twitter" />
                  </DropdownItem>
                )}
                {telegramurl && (
                  <DropdownItem href={telegramurl} target="_blank">
                    <img src="/img/telegram.png" alt="telegram" />
                  </DropdownItem>
                )}
                {dextool && (
                  <DropdownItem href={dextool} target="_blank">
                    <img src="/img/dextool.png" alt="dextool" />
                  </DropdownItem>
                )}
                {linktreeurl && (
                  <DropdownItem href={linktreeurl} target="_blank">
                    <img src="/img/linktree.png" alt="linktree" />
                  </DropdownItem>
                )}
                 {raydiumurl && (
                  <DropdownItem href={raydiumurl} target="_blank">
                    <img src="/img/raydium.png" alt="raydium" />
                  </DropdownItem>
                )}
              </DropdownContent>
            </SocialMedia>
          </>
        )}
      </HeaderWrapper>
    </>
  );
}

const WalletBtn = styled.div`
display: none;
  @media (max-width: 540px) {
display: block;
    width: 100%;
  padding: 20px 0px 50px;
  }
`

const HeaderWrapper = styled.header`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  padding: 30px 40px;
  font-size: 24px;
  color: var(--font-color1);
  font-weight: 400;
  z-index: 999;
  @media (max-width: 1028px) {
  // padding: 15px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 540px) {
  padding: 30px 15px;
  position: unset!important;
    justify-content: space-around;
  }
`;

const LogoDiv = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  padding-left: 60px;
@media (max-width: 1028px) {
  width: 50%;
}
@media (max-width: 540px) {
  padding-left: 0px;
  // width: 100%;
}
`;

const Logo = styled.img`
  width: 30%;
  max-width: 100%;
  object-fit: contain;
  @media (max-width: 1028px) {
    width: 150px;
  }
  @media (max-width: 991px) {
    width: 130px;
  }
  @media (max-width: 540px) {
    width: 85%;
    margin-left: 0px;
    margin-top: 0px;
  }
`;

const SocialMedia = styled.div`
 width: 50%;
 display: flex;
 justify-content: flex-end;
 position: relative;
//  background-color: var(--background-color);
  border-radius: 50px;
  padding: 12px 15px;
 @media (max-width: 1028px) {
  width: 50%;
}
  @media (max-width: 540px) {
  width: 100%;
  margin-top: 0px;
  margin-left: 0px;
}
`;

const DropdownContent = styled.div`
  display: flex;
  color: white;
  width: 100%;
  // filter: invert(1);
  // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999;
  // padding: 10px 20px 5px;
  justify-content: flex-end;
  @media (max-width: 1028px) {
   padding: 5px 20px 5px;
  // justify-content: space-evenly;
  }
  @media (max-width: 540px) {
  //  padding: 5px 0px 5px;
      justify-content: center;
    // justify-content: center;
  }
`;

const DropdownItem = styled.a`
display: flex;
align-items: center;
 background-color: var(--background-color);
padding: 10px 10px;
text-decoration: none;
border-radius: 10px;
color: white;
font-size: 16px;
border: 1px solid #303A44;
border-bottom-width: 3px;
margin: 0px 10px;
transition: background-color 0.2s ease-in-out; 
  & img{
    width: 25px;
    height: 25px;
        object-fit: contain;
    // margin: 0px 5px;
  // filter: invert(1);
  @media (max-width: 540px) {
    // width: 17px!important;
    // height: 17px!important;
    margin-right: unset;
    // margin: 0px 5px;
  }
}
  &:hover {
     background-color: var(--font-color2);
    // transform: scale(1.1);
  }
  @media (max-width: 991px) {
    font-size: 14px;
  }
  @media (max-width: 540px) {
    // font-size: 12px;
    // padding: 0px;
  }
`;

export default Header;