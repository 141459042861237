import * as React from "react";
import styled from "styled-components";
import { telegramurl, twitterurl, dextool, uniswapurl, linktreeurl, raydiumurl } from '../../url';

const data = [
  {
    id: 1,
    image: "/img/icon.png",
    title: "Pump",
    url: uniswapurl
  },
  {
    id: 2,
    image: "/img/twitter.png",
    title: "twitter",
    url: twitterurl
  },
  {
    id: 3,
    image: "/img/telegram.png",
    title: "telegram",
    url: telegramurl
  },
  {
    id: 4,
    image: "/img/Dextool.png",
    title: "dextool",
    url: dextool
  },
  {
    id: 5,
    image: "/img/linktree.png",
    title: "linktree",
    url: linktreeurl
  },
  {
    id: 6,
    image: "/img/raydium.png",
    title: "raydium",
    url: raydiumurl
  }
];

function MyComponent() {

  const handleBuyNowClick = () => {
    window.open(uniswapurl, '_blank');
  };

  const handleSocialLinkClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  return (
    <MainContainer>
      <SocilMediaSection>
        <Button onClick={handleBuyNowClick}>
          Buy Now
        </Button>
        <Heading>get spaced out! join our socials</Heading>
        <SocialIcons>
          {data.map((item) => (
            item.url && (
              <SocialDiv>
                <ImageDiv onClick={() => handleSocialLinkClick(item.url)}>
                  <Image src={item.image} alt="footer image" />
                </ImageDiv>
                <SocialTitle>{item.title}</SocialTitle>
              </SocialDiv>
            )
          ))}
        </SocialIcons>
      </SocilMediaSection>
      <FooterContainer>
        <MemeImage src="/img/footer.png" alt="coin" loading="lazy" />
      </FooterContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  margin-top: 150px;
  display: flex;
  // flex-direction: column;
  color: var(--Font-color, #fff);
  font-weight: 400;
  padding: 20px 100px 20px;
  width: 100%;
  align-items: center;
  @media (max-width: 1028px) {
  padding: 20px 60px 20px;
    // padding: 190px 0px 0px;
  }
  @media (max-width: 991px) {
    margin-top: 80px;
    padding: 20px 0px 20px;
  }
  @media (max-width: 540px) {
  position: relative;
    padding: 80px 0px 20px;
    margin-top: 20px;
  }
`;

const FooterContainer = styled.footer`
 width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1028px) {
    // padding: 0px 30px;
  }
  @media (max-width: 991px) {
    padding: 7px 15px;
    max-width: 100%;
    width: 25%;
    // justify-content: flex-end;
  }
  @media (max-width: 540px) {
  width: 80%;
    border-radius: 20px;
    flex-direction: column;
    margin-top: 0px;
    gap: 0px;
    padding: 7px 15px 15px;
  }
`;

const SocilMediaSection = styled.div`
width: 80%;
display: flex;
align-items: center;
flex-direction: column;
justify-content: space-between;
padding-right: 150px;
@media (max-width: 1028px) {
padding-right: 100px;
padding-bottom: 50px;
  // width: 15%;
}
  @media (max-width: 991px) {
    width: 75%;
    padding: 0px 50px 50px 40px;
  }
@media (max-width: 540px) {
  padding: 0px 10px 0px 10px;
  width: 20%;
}
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  // margin: 0px 0px 30px;
  justify-content: flex-start;
  width: 100%;
  justify-content: space-between;
  // filter: invert(1);
@media (max-width: 1028px) {
  // justify-content: center;
}
@media (max-width: 540px) {
    flex-direction: column;
  margin: 5px 5px 10px;
  justify-content: center;
}
`;

const SocialDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageDiv = styled.div`
  background-color: var(--background-color);
  border: 1px solid #303A44;
  border-bottom-width: 3px;
  margin: 5px;
  padding: 15px;
  border-radius: 10px;
  transition: background-color 0.2s ease-in-out; 
  width: fit-content;
  @media (max-width: 991px) {
    padding: 10px;
  }
  @media (max-width: 540px) {
    margin: 10px 5px;
  }
    &:hover {
     background-color: var(--font-color2);
    // transform: scale(1.1);
  }
`;

const Image = styled.img`
  height: 25px;
  width: 25px;
  // margin: 0px 10px;
  max-width: 100%;
  // margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "72px" : "0")};
  cursor: pointer;
  @media (max-width: 991px) {
    margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "40px" : "0")};
  }
  @media (max-width: 540px) {
    // height: 20px;
    // width: 20px;
  }
`;

const SocialTitle = styled.h4`
  font-size: 25px;
 @media (max-width: 991px) {
  font-size: 22px;
 }
    @media (max-width: 540px) {
   visibility: hidden;
   display: none;
    }
`;

const MemeImage = styled.img`
  width: 90%;
  max-width: 100%;
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  bottom: 0px;
  z-index: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 540px) {
    // left: unset;
    width: 90%;
    // right: -15px;
    // width: 200px;
    // margin-top: 30px;
  }
`;

const Button = styled.button`
width: fit-content;
display: flex;
align-self: baseline;
font-size: 38px;
background: var(--font-color1);
color: var(--background-color);
padding: 10px 100px;
border-radius: 50px;
align-items: center;
margin-bottom: 200px;
margin-left: -50px;
  @media (max-width: 1028px) {
margin-left: -10px;
padding: 10px 70px;
font-size: 36px;
margin-bottom: 100px;
  }
@media (max-width: 540px) {
    font-size: 15px;
    padding: 10px 20px;
    display: none;
}
`;

const Heading = styled.h2`
  width: 100%; 
  text-align: left;
  font-family: var(--font-family);
  font-size: 50px;
  line-height: 75px;
  margin-bottom: 50px;
  color: var(--font-color1);
  @media (max-width: 1028px) {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 60px;
  }
  @media (max-width: 540px) {
  position: absolute;
    top: -70px;
    text-align: center;
    font-size: 44px;
        line-height: 52px;
    margin: 0px 10px 40px;
    width: 100%;
    padding: 0px 20px 0px;
    left: 0px;
    text-align: left;
  }
`;

export default MyComponent;