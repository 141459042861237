import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import AudioPlayer from './AudioPlayer';
import Audio2 from './Audio2';
import { telegramurl, twitterurl, dextool, uniswapurl, linktreeurl } from '../../url';

const AudioDivMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 150px 0px 0px;
  .coin2{
  margin-top: -50px;
  margin-left: -100px;
   @media (max-width: 540px) {
  margin-top: 50px;
  margin-left: 0px;
   width: 40%;
   }
  }
    @media (max-width: 540px) {
       margin: 0px 0px 70px;
    }
`;

const Heading = styled.h2`
  width: 80%; 
  text-align: center;
  font-family: var(--font-family);
  font-size: 100px;
  line-height: 75px;
  margin-bottom: 50px;
  color: var(--font-color1);
  @media (max-width: 1028px) {
    font-size: 75px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    font-size: 60px;
    // margin-bottom: 0px;
    line-height: 60px;
  }
  @media (max-width: 540px) {
  margin-bottom: 0px;
    text-align: center;
    font-size: 45px;
    width: 100%;
    padding: 0px 20px 0px;
  }
`;

const BgCoin = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 100px;
  left: 0px;
  z-index: 0;
    & img{
    width: 32%;
    height: 100%;
    }
    @media (max-width: 540px) {
      top: 185px;
    }
`;

const Button = styled.button`
margin: 100px 150px 40px 0px;
width: fit-content;
display: flex;
align-self: end;
font-size: 32px;
background: var(--font-color1);
color: var(--background-color);
padding: 10px 100px;
border-radius: 50px;
align-items: center;
z-index: 99999999;
@media (max-width: 540px) {
margin: 100px 40px 0px 0px;
  font-size: 30px;
    padding: 10px 69px;
}
`;

const Audio = () => {
  const handleBuyNowClick = () => {
    window.open(uniswapurl, '_blank');
  };

  return (
    <AudioDivMain>
      <Heading>SpaceKitty Sounds</Heading>
      <BgCoin>
        <img style={{ marginLeft: '50px' }} src="/img/coin.png" alt="twitter" loading='lazy' />
        <img src="/img/coin.png" alt="twitter" loading='lazy' />
      </BgCoin>
      {/* <AudioPlayer audioSrc='/audio/Audio.mp3' /> */}
      <Audio2 src='/audio/Audio.mp3' />
      <Button onClick={handleBuyNowClick}>
        Buy Now
      </Button>
      <img className='coin2' src="/img/coin.png" alt="twitter" loading='lazy' />
    </AudioDivMain>
  );
};

export default Audio;