import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { smartcontractAddress, etherscanurl } from '../../url';
import Tooltip from './Tooltip';
// import CopyImg from '../../../public/img/copy.png'

const CryptoAddressContainer = styled.div`
  position: absolute;
  left: 0px;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 99999999;
  @media (max-width: 1028px) {
    padding: 0px;
    // width: 90%;
  }
  @media (max-width: 991px) {
    width: 100%;
    // top: -110px;
  }
  @media (max-width: 540px) {
    width: 100%;
    // position: unset;
    // margin-top: -20px;
  }
`;

const CryptoAddressText = styled.div`
  font-size: 50px;
  margin: auto 0;
  color: var(--font-color2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 1028px) {
    font-size: 35px;
  }
  @media (max-width: 991px) {
    font-size: 17px;
    max-width: 100%;
  }
  @media (max-width: 540px) {
    font-size: 25px;
    font-weight: normal;
  }
`;

const CryptoAddressIcon = styled.img`
  // aspect-ratio: 1;
  object-fit: contain;
  width: 30px;
  // filter: invert(1);
  margin-left: 15px;
  @media (max-width: 540px) {
    width: 25px;
  }
`;

function CryptoAddress() {
  const [isCopied, setIsCopied] = useState(false);
  const [isMobileOrTabletScreen, setIsMobileOrTabletScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      setIsMobileOrTabletScreen(screenWidth <= 2000); // Adjusted for max-width 540px
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCopyAddressClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Hide tooltip after 2 seconds
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  const handleAddressClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  // Manipulate the smartcontractAddress to show only first 5 and last 5 characters
  const formattedAddress =
    smartcontractAddress.length > 10
      ? `${smartcontractAddress.slice(0, 8)}...${smartcontractAddress.slice(-5)}`
      : smartcontractAddress;

  return (
    <>
      <CryptoAddressContainer>
        {isCopied && <Tooltip>Copied!</Tooltip>}
        <CryptoAddressText onClick={() => handleAddressClick(etherscanurl)}>
          {/* <span style={{ color: "var(--font-color1)", fontWeight: '600' }}>$MDOG :</span>{" "} */}
          {isMobileOrTabletScreen ? formattedAddress : smartcontractAddress}
        </CryptoAddressText>
        <CryptoAddressIcon
          style={{ zIndex: 99999 }}
          onClick={() => handleCopyAddressClick(smartcontractAddress)}
          loading="lazy"
          src="/img/copy.png"
          alt="Crypto Address Icon"
        />
      </CryptoAddressContainer>
      {/* <Image src="/img/smartcontract.png" alt="smartcontract" /> */}
    </>
  );
}

export default CryptoAddress;