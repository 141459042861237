import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { telegramurl, twitterurl, dextool, uniswapurl, linktreeurl } from '../../url';
import WalletAddress from './WalletAddressButton'

function MyComponent() {
  const handleBuyNowClick = () => {
    window.open(uniswapurl, '_blank');
  };

  return (
    <MainSection>
      <WalletBtn >
        <WalletAddress />
      </WalletBtn>
      <ContentWrapper>
        <ContentColumn>
          {/* <ContentContainer> */}
          <Title>
            Hello, I am a <TitleSpan>Spacexcat</TitleSpan>, ruling the streets with style and a bit of <TitleSpan2>cattitude</TitleSpan2>
          </Title>
          {/* <Title>Hello, I am a <TitleSpan>Spacexcat</TitleSpan>, ruling the streets with style and a bit of<TitleSpan2>cattitude</TitleSpan2>  </Title> */}
          <Button onClick={handleBuyNowClick}>
            Buy Now
          </Button>
          {/* </ContentContainer> */}
        </ContentColumn>
        <ImageColumn>
          <HeroImage src="/img/hero.png" alt="Hero Image" />
        </ImageColumn>
      </ContentWrapper>
    </MainSection>
  );
}

const WalletBtn = styled.div`
padding-top: 100px;
margin-bottom: -20px;
 display: flex;
 width: 100%;
 z-inedx: 9999999;
  @media (max-width: 1028px) {
    margin-bottom: 0px;
  }
   @media (max-width: 991px) {
    margin-bottom: 0px;
    padding-top: 150px;
    // margin-bottom: -20px;
  }
      @media (max-width: 540px) {
      display: none;
      }
`

const MainSection = styled.section`
  display: flex;
  min-height: 100vh;
      flex-direction: column;
  // background-image: url('/img/herobg.png'); 
  // background-size: cover; 
  // background-position: center; 
  // background-repeat: no-repeat;
  @media (max-width: 991px) {
    // flex-direction: column;
    // gap: 0;
    // justify-content: center;
  }
  @media (max-width: 540px) {
    min-height: 100vh;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  // gap: 20px;
  width: 100%;
  position: relative;
  padding-top: 100px;
  // flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  padding-top: 0px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 540px) {
    align-items: center;
  }
  
`;

const Title = styled.h1`
  color: var(--font-color1);
  font-size: var(--hero-font-size-desktop);
  font-family: var(--font-family);
  text-align: left;
  width: 85%;
  z-index: 99999;
  @media (max-width: 1028px) {
    width: 100%;
    margin-bottom: 0px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 50px; 
    text-align: center;
  }
  @media (max-width: 540px) {
    // top: 70%;
    font-size: 30px; 
    line-height: 45px;
    text-align: left;
  }
`;

const TitleSpan = styled.span`
  color: #C8FF2A;
  width: fit-content;
  @media (max-width: 540px) {
      font-size: 30px; 
  }
`;

const TitleSpan2 = styled.span`
  color: #FB2AFF;
  width: fit-content;
  @media (max-width: 540px) {
      font-size: 30px; 
  }
`;
const ContentColumn = styled.div`
  display: flex;
  width: 50%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
   padding-left: 100px;
     @media (max-width: 991px) {
        width: 100%;
   padding: 75px 50px 0px;

     }
   @media (max-width: 540px) {
   padding: 10px 30px 0px;
   }
`;

const ImageColumn = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  z-index: 9; 
  @media (max-width: 1028px) {
    // width: 100%;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0px;
    align-items: center;
  padding: 50px 0px 0px;

  }
  @media (max-width: 540px) {
    top: 55%;
    margin-left: 0px;
    width: 100%;
  }
`;

const HeroImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 65%;
  @media (max-width: 1028px) {
    object-fit: contain;
    width: 60%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    width: 30%;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  @media (max-width: 540px) {
    width: 80%;
    margin-bottom: 0px;
  }
`;

const Button = styled.button`
margin-top: 30px;
width: fit-content;
display: flex;
align-self: baseline;
font-size: 28px;
background: var(--font-color1);
color: var(--background-color);
padding: 10px 40px;
border-radius: 50px;
align-items: center;
@media (max-width: 991px) {
align-self: center;
}
@media (max-width: 540px) {
    margin-top: 30px;
    font-size: 30px;
    padding: 10px 69px;
align-self: baseline;

}
`;

export default MyComponent;