import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { telegramurl, twitterurl, dextool, uniswapurl, linktreeurl } from '../../url';

const images = [
  { src: "/img/1.jpg" },
  { src: "/img/2.jpg" },
  { src: "/img/3.jpg" },
  { src: "/img/4.jpg" },
  { src: "/img/5.jpg" },
  { src: "/img/6.jpg" },
  { src: "/img/7.jpg" },
  { src: "/img/8.jpg" },
];

const SliderSection = styled.div`
 margin-top: 100px;
 display: flex;
 flex-direction: column;
`;

const SliderContainer = styled.section`
  display: flex;
  overflow: hidden;
  width: 100%;
  @media (max-width: 540px) {
  margin-bottom: 100px;
  }

`;

const slideAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-${(images.length * (200 + 20))}px); }
`;

const SliderWrapper = styled.div`
  display: flex;
  animation: ${slideAnimation} 3s linear infinite;
`;

const Image = styled.img`
  width: 400px;
  height: 400px;
  // border-radius: 50%;
  margin-right: 20px;
  @media (max-width: 540px) {
    width: 300px;
    height: 300px;
    margin-right: 10px;
  }
`;

const Slide = styled.div`
  width: 400px;
  margin-right: 40px;
  flex-shrink: 0;
  // border-radius: 15px;
  overflow: hidden;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  &:hover .tagline {
    opacity: 1;
    transform: translateY(0);
  }
  @media (max-width: 540px) {
    width: 300px;
  }
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 10px;
  color: var(--font-color1);
  font-family: var(--font-family);
  font-size: 100px;
  line-height: 75px;
   @media (max-width: 1028px) {
    font-size: 75px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    font-size: 60px;
    // margin-bottom: 0px;
    line-height: 60px;
  }
  @media (max-width: 540px) {
    text-align: center;
    font-size: 45px;
    width: 100%;
    padding: 0px 20px 0px;
  }
`;

const Button = styled.button`
margin: 30px 0px 40px;
width: fit-content;
display: flex;
align-self: center;
font-size: 28px;
background: var(--font-color1);
color: var(--background-color);
padding: 10px 40px;
border-radius: 50px;
align-items: center;
@media (max-width: 540px) {
    margin-top: 0px;
   font-size: 30px;
    padding: 10px 69px;
}
`;

const duplicatedImages = [...images, ...images];

const Slider = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const sliderWidth = sliderRef.current.offsetWidth;
    const totalWidth = duplicatedImages.length * (300 + 20);
    const animationDuration = totalWidth / sliderWidth * 5;

    sliderRef.current.style.animationDuration = `${animationDuration}s`;
  }, []);

  const handleBuyNowClick = () => {
    window.open(uniswapurl, '_blank');
  };

  return (
    <SliderSection>
      <Heading id='gallery'>Meowstronaut Madness</Heading>
      <Button onClick={handleBuyNowClick}>
        Buy Now
      </Button>
      <SliderContainer ref={sliderRef}>
        <SliderWrapper>
          {duplicatedImages.map((image, index) => (
            <Slide key={index}>
              <Image key={index} src={image.src} alt={`Slide ${index + 1}`} />
            </Slide>
          ))}
        </SliderWrapper>
      </SliderContainer>
    </SliderSection>
  );
};

export default Slider;