import React from 'react';
import styled from 'styled-components';
import { uniswapurl } from '../../url';

const AudioDivMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 150px 0px 0px;
  @media (max-width: 540px) {
    margin: 0px 0px 120px;
  }
`;

const Heading = styled.h2`
  width: 80%; 
  text-align: center;
  font-family: var(--font-family);
  font-size: 100px;
  line-height: 75px;
  margin-bottom: 50px;
  color: var(--font-color1);
    @media (max-width: 1028px) {
    font-size: 75px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    font-size: 60px;
    // margin-bottom: 0px;
    line-height: 60px;
  }
  @media (max-width: 540px) {
    text-align: center;
    font-size: 45px;
    width: 100%;
    padding: 0px 20px 0px;
  }
`;

const Button = styled.button`
  position: absolute;
  transform: rotate(80deg);
  width: fit-content;
  display: flex;
  right: -50px;
  font-size: 32px;
  background: var(--font-color1);
  color: var(--background-color);
  padding: 10px 100px;
  border-radius: 50px;
  align-items: center;
@media (max-width: 1028px) {
  padding: 10px 70px;
  right: -70px;
    }
  @media (max-width: 540px) {
  position: unset;
  transform: rotate(0deg);
  right: 0px;
    margin-top: 0px;
   font-size: 30px;
    padding: 10px 69px;
  }
`;

const StyledImage = styled.img`
 width: 70%;
  margin-bottom: 50px;
  @media (max-width: 1028px) {
    width: 70%;
  }
  @media (max-width: 540px) {
  margin-bottom: 20px;
    width: 80%;
  }
`;

const Video = () => {
    const handleBuyNowClick = () => {
        window.open(uniswapurl, '_blank');
    };

    return (
        <AudioDivMain>
            <Heading>SpaceTail TV</Heading>
            {/* <StyledVideo autoPlay loop muted>
                <source src="/img/video1.gif" type="gif" />
                Your browser does not support the video tag.
            </StyledVideo> */}
             <StyledImage src="/img/video1.gif" alt="SpaceKitty" />
            <Button onClick={handleBuyNowClick}>
                Buy Now
            </Button>
        </AudioDivMain>
    );
};

export default Video;